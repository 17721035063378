<template>
  <v-layout
    align-center
    justify-center
    column
    fill-height

    class="mt-5 mb-5"
  >
    <v-img
      :src="require('@/assets/no_se_encontraron_resultados.png')"
      class="image-sizes"
    />
    <p class="text-title">
      No se encontraron resultados
    </p>
    <p class="text-body">
      No hay resultados asociados a esta búsqueda, intenta quitando o ajustando algunos filtros.
    </p>
  </v-layout>
</template>

<script>
export default {
  name: 'NoResults',
};
</script>

<style scoped>
  .text-title{
    color: rgba(0,0,0,0.87);
    font-family: Lato;
    font-size: 34px;
    font-weight: 900;
    letter-spacing: 0.25px;
    line-height: 42px;
  }
  .text-body{
    max-width: 463px;
    color: rgba(0,0,0,0.6);
    font-family: Lato;
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 24px;
    text-align: center;
  }

  .image-sizes{
    max-width:222px;
    width:222px;
    max-height:210px;
    height:210px;

  }
</style>
