<template>
  <v-container>
    <v-layout
      row
      wrap
    >
      <v-flex>
        <v-layout
          align-center
          justify-start
          row
          fill-height
        >
          <h2 class="font-title">
            Mis Postulaciones
          </h2>
        </v-layout>
      </v-flex>
      <v-flex
        :xs12="!$vuetify.breakpoint.mdAndUp"
      >
        <v-layout
          align-center
          justify-end
          row
          fill-height
        >
          <v-combobox
            v-model="filters_selected"
            :items="filters"
            item-value="filter"
            item-text="text"
            label="Filtrar por"
            :style="$vuetify.breakpoint.mdAndUp ?'max-width: 400px'
              : 'max-width: 100%; margin-top:20px;'"
            :full-width="!$vuetify.breakpoint.mdAndUp"
            outline
            multiple
            chips
            @change="onFiltersChange"
          />
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <v-container
          v-if="filtered_applications.length > 0"
          class="card-container"
        >
          <!-- Table -->
          <v-data-table
            :headers="headers"
            :items="filtered_applications"
            class="elevation-0 ml-4 mr-4 pa-0"
            hide-actions
          >
            <template v-slot:items="props">
              <!-- Puesto -->
              <td class="table-td">
                <v-layout
                  align-center
                  justify-start
                  row
                  fill-height
                >
                  <div class="avatar-container">
                    <v-avatar
                      color="#E0E0E0"
                      tile
                      size="48"
                      style="border-radius: 6px;"
                    >
                      <v-icon
                        color="E9E9E9"
                      >
                        {{ jobIcon(props.item.offer) }}
                      </v-icon>
                    </v-avatar>
                  </div>
                  <div class="table-text">
                    {{ props.item.offer.title }}
                  </div>
                </v-layout>
              </td>
              <!-- Empresa -->
              <td class="table-td">
                <v-layout
                  align-center
                  justify-start
                  row
                  fill-height
                >
                  <div class="table-text">
                    {{ props.item.offer.computed_title }}
                  </div>
                </v-layout>
              </td>
              <!-- Ciudad -->
              <td class="table-td">
                <v-layout
                  align-center
                  justify-start
                  row
                  fill-height
                >
                  <div class="table-text">
                    {{ props.item.offer.city_name }}
                  </div>
                </v-layout>
              </td>
              <!--  Fecha Envio -->
              <td class="table-td">
                <v-layout
                  align-center
                  justify-start
                  row
                  fill-height
                >
                  <div class="table-text text-nowrap">
                    {{ props.item.offer.published_date }}
                  </div>
                </v-layout>
              </td>
              <!-- Mensaje -->
              <td
                v-if="false"
                class="table-td"
              >
                <v-layout
                  align-center
                  justify-start
                  row
                  fill-height
                >
                  <div
                    class="table-message"
                    @click="chatLink(props.item.uuid)"
                  >
                    <v-badge
                      color="red"
                      style="width: 30px; height: 30px;"
                    >
                      <template
                        v-slot:badge
                        style="height: 10px; width: 10px;"
                      >
                        <span>
                          1
                        </span>
                      </template>
                      <img
                        :src="require('@/assets/candidates/application_message.svg')"
                        style="height: 24px; width:24px;"
                      >
                    </v-badge>
                  </div>
                </v-layout>
              </td>
              <!-- Estado -->
              <td class="table-td">
                <v-layout
                  align-center
                  justify-start
                  row
                  fill-height
                >
                  <div
                    class="table-text-state  table-state text-nowrap"
                    :style="'background-color: '+ getStatusColor(props.item.polite_status)"
                  >
                    {{ props.item.polite_status }}
                  </div>
                </v-layout>
              </td>
            </template>
          </v-data-table>
        </v-container>
        <v-container v-else>
          <v-layout>
            <v-flex
              xs12
            >
              <NoResults />
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from 'axios';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';
import NoResults from '@/components/offers/NoResults.vue';

export default {
  name: 'MyApplications',
  components: {
    NoResults,
  },
  mixins: [
    JWTConfigMixin,
  ],
  data() {
    return {
      applications: [],
      filtered_applications: [],
      search: '',
      filters_selected: '',
      filters_numbers: [],
      filters: [
        {
          text: 'Abierta',
          filter: 1,
        },
        {
          text: 'En proceso',
          filter: 2,
        },
        {
          text: 'Cerrada',
          filter: 3,
        },
        {
          text: 'Contratado',
          filter: 4,
        },
      ],
      headers: [
        {
          text: 'Puesto',
          value: 'name',
          align: 'left',
          class: '',
          sortable: false,
        },
        {
          text: 'Empresa',
          value: 'empresa',
          align: 'left',
          class: '',
          sortable: false,
        },
        {
          text: 'Ciudad',
          value: 'ciudad',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Fecha Envio',
          value: 'fechaenvio',
          align: 'left',
          class: '',
          sortable: false,
        },
        /* {
          text: 'Mensaje',
          value: 'mensaje',
          align: 'left',
          class: '',
          sortable: false,
        }, */
        {
          text: 'Estado',
          value: 'estado',
          align: 'left',
          class: '',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    lowercaseSearch() {
      return this.search.toLowerCase();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadApplications();
    });
  },
  methods: {
    loadApplications() {
      this.$store.commit('setLoading');
      axios.get(this.$store.state.endpoints.candidates.candidateApplication(this.filters_numbers),
        this.jwtConfig)
        .then(response => {
          this.applications = response.data;
          this.filtered_applications = [...this.applications];
          this.$store.commit('unsetLoading');
        })
        .catch(error => {
          console.error(error);
          this.$store.commit('unsetLoading');
        });
    },
    chatLink(uuid) {
      console.log(uuid, 'UUID');
      this.$router.push({ name: 'candidate:profile:chat:candidate', params: { uuid } });
    },
    onFiltersChange() {
      if (this.filters_selected.length > 0) {
        this.filtered_applications = [];
        this.filters_selected.forEach(filter => {
          this.applications.forEach(application => {
            if (application.polite_status === filter.text) {
              this.filtered_applications.push(application);
            }
          });
        });
      } else {
        this.filtered_applications = [...this.applications];
      }
    },
    getStatusColor(politeStatus) {
      if (politeStatus === 'En proceso') {
        return '#6EBB3D';
      }
      if (politeStatus === 'Abierta') {
        return '#30B0BF';
      }
      if (politeStatus === 'Cerrada') {
        return '#C5C5C5';
      }
      if (politeStatus === 'Contratado') {
        return 'green';
      }
      return '';
    },
    jobIcon(offer) {
      if (!offer) {
        return 'fal fa-circle-notch fa-spin';
      }
      const iconDict = {
        'Operaciones y Logística': 'fal fa-dolly',
        'Administración y Finanzas': 'fal fa-analytics',
        RRHH: 'fal fa-id-badge',
        Comercial: 'fal fa-briefcase',
        Marketing: 'fal fa-lightbulb-on',
        Tecnología: 'fal fa-phone-laptop',
        Seguridad: 'fal fa-shield-check',
      };
      return iconDict[offer.transformed_area] ? iconDict[offer.transformed_area]
        : 'fal fa-question-circle';
    },
  },
};
</script>

<style scoped>
.table-message{
  cursor: pointer;
}
.card-container {
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.11);
}
.combobox-filters{
  max-width: 300px !important;
  max-height: 70px !important;
  box-sizing: border-box;
  border: 1px solid rgba(0,0,0,0.54);
  border-radius: 6px;
}
.table-text{
  max-height: 64px;
  max-width: 200px;
  min-height: 32px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 18px;
  letter-spacing: 0.14px;
  line-height: 32px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.table-text-state{
  height: 20px;
  color: #FFFFFF;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.4px;
  line-height: 20px;
  text-align: center;
}

.table-image{
  height: 43px;
  width: 43px;
  margin: 10px;
  border-radius: 4px;
}
.table-layout{
  height: 49px;
  width: 49px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.table-td{
  height: 100px;
}
.text-nowrap{
  white-space: nowrap;
}
.table-state{
  height: 20px;
  border-radius: 10px;
  padding-left: 8px;
  padding-right: 8px;
}
.font-title{
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 34px;
  font-weight: 900;
  letter-spacing: 0.25px;
  line-height: 42px;
}
.avatar-container{
  margin-right: 16px;
}
</style>
